<template>
  <div class="babysitter">
    <div class="card">
      <div class="form">
        <el-input class="search-item" v-model="queryInfo.number" size="small" placeholder="月嫂编号" />
        <el-input class="search-item" v-model="queryInfo.name" size="small" placeholder="月嫂姓名" />
        <el-button size="small" type="primary" @click="getBabysitterList">查询</el-button>
        <el-button size="small" @click="reset">重置</el-button>
      </div>
      <div class="btns">
        <el-button size="small" type="primary" @click="add">新增</el-button>
        <el-button size="small" type="primary" @click="exportAll">导出</el-button>
      </div>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%"
      height="calc(100vh - 240px)"
    >
      <el-table-column type="index" label="序号" />
      <el-table-column label="编号" prop="number" />
      <el-table-column label="轮播图" prop="covers">
        <template slot-scope="scope">
          <el-image
            v-if="scope.row.covers && scope.row.covers.length"
            class="avatar"
            fit="cover"
            :src="scope.row.covers[0]"
            :preview-src-list="scope.row.covers"
          />
        </template>
      </el-table-column>
      <el-table-column label="工装照" prop="avatar">
        <template slot-scope="scope">
          <el-image
            v-if="scope.row.avatar"
            class="avatar"
            fit="cover"
            :src="scope.row.avatar"
            :preview-src-list="[scope.row.avatar]"
          />
        </template>
      </el-table-column>
      <el-table-column label="视频" prop="video">
        <template slot-scope="scope">
          <div v-if="scope.row.video">
            <el-tag>已上传</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="推荐">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.is_recommend"
            @change="onRecommendChange($event, scope.row.id)"
          />
        </template>
      </el-table-column>
      <el-table-column label="推荐排序" width="150">
        <template slot-scope="scope">
          <el-input
            v-model="scope.row.recommend_sort"
            size="small"
          >
            <el-button slot="append" icon="el-icon-check" @click="recommendSortSave(scope.row)" />
          </el-input>
        </template>
      </el-table-column>
      <el-table-column label="姓名" prop="name" />
      <el-table-column label="年龄" prop="age" />
      <el-table-column label="生肖" prop="chinese_zodiac" />
      <el-table-column label="星座" prop="constellation" />
      <el-table-column label="身高" prop="height" />
      <el-table-column label="体重" prop="weight" />
      <el-table-column label="民族" prop="nation" />
      <el-table-column label="籍贯" prop="census" />
      <el-table-column label="教育程度" prop="education">
        <template slot-scope="scope">
          {{ educationMap[scope.row.education] }}
        </template>
      </el-table-column>
      <el-table-column label="经验" prop="experience" />
      <el-table-column label="语言" prop="ability_language" />
      <el-table-column label="烹饪能力" prop="ability_cook">
        <template slot-scope="scope">
          <el-popover
            placement="top"
            title="详情"
            width="200"
            trigger="hover"
            :content="scope.row.ability_cook">
            <el-button type="text" slot="reference">
              {{ scope.row.ability_cook | cutStr(10) }}
            </el-button>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column label="家庭成员" prop="family_members">
        <template slot-scope="scope">
          <el-popover
            placement="top"
            title="详情"
            width="200"
            trigger="hover"
            :content="scope.row.family_members">
            <el-button type="text" slot="reference">
              {{ scope.row.family_members | cutStr(10) }}
            </el-button>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column label="客户印象" prop="impression">
        <template slot-scope="scope">
          <el-popover
            placement="top"
            title="详情"
            width="200"
            trigger="hover"
            :content="scope.row.impression">
            <el-button type="text" slot="reference">
              {{ scope.row.impression | cutStr(10) }}
            </el-button>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column label="服务标签" prop="tags">
        <template slot-scope="scope">
          <el-popover trigger="hover" placement="top">
            <el-tag
              v-for="(item, index) in scope.row.tags"
              :key="index"
            >
              {{ item }}
            </el-tag>
            <div slot="reference" class="name-wrapper">
              <el-tag size="medium">查看</el-tag>
            </div>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column label="组员点评" prop="remark">
        <template slot-scope="scope">
          <el-popover
            placement="top"
            title="详情"
            width="200"
            trigger="hover"
            :content="scope.row.remark">
            <el-button type="text" slot="reference">
              {{ scope.row.remark | cutStr(10) }}
            </el-button>
          </el-popover>
        </template>
      </el-table-column>
      <!-- <el-table-column label="客户评价" prop="customer_remark" /> -->
      <el-table-column label="客户肯定" prop="customer_confirm">
        <template slot-scope="scope">
          <el-popover trigger="hover" placement="top">
            <el-tag
              v-for="(item, index) in scope.row.customer_confirm"
              :key="index"
            >
              {{ item }}
            </el-tag>
            <div slot="reference" class="name-wrapper">
              <el-tag size="medium">查看</el-tag>
            </div>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column width="140" label="身份证" prop="identity">
        <template slot-scope="scope">
          <el-image
            v-if="scope.row.identity && scope.row.identity.up"
            class="avatar"
            fit="cover"
            :src="scope.row.identity.up"
            :preview-src-list="[scope.row.identity.up]"
          />
          <el-divider v-if="scope.row.identity && scope.row.identity.down" direction="vertical" />
          <el-image
            v-if="scope.row.identity && scope.row.identity.down"
            class="avatar"
            fit="cover"
            :src="scope.row.identity.down"
            :preview-src-list="[scope.row.identity.down]"
          />
        </template>
      </el-table-column>
      <el-table-column label="体检报告" prop="medical_report">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.medical_report">已上传</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="背景调查" prop="reference_check">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.reference_check">已上传</el-tag>
        </template>
      </el-table-column>
      <el-table-column width="200" label="价格" prop="price">
        <template slot-scope="scope">
          <el-popover
            placement="top"
            title="详情"
            width="200"
            trigger="hover"
            :content="scope.row.price">
            <el-button type="text" slot="reference">
              {{ scope.row.price | cutStr(10) }}
            </el-button>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column width="200" label="创建时间" prop="created_at" />
      <el-table-column width="200" label="修改时间" prop="updated_at" />
      <el-table-column label="操作" fixed="right" width="200">
        <template slot-scope="scope">
          <el-button
            icon="el-icon-view"
            circle
            size="small"
            @click="handleView(scope.$index, scope.row)"
          />
          <el-button
            icon="el-icon-edit"
            circle
            size="small"
            @click="handleEdit(scope.$index, scope.row)"
          />
          <el-button
            icon="el-icon-video-camera-solid"
            circle
            size="small"
            @click="handleVideo(scope.$index, scope.row)"
          />
          <el-button
            icon="el-icon-delete"
            type="danger"
            circle
            size="small"
            @click="handleDelete(scope.$index, scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNum"
        :page-sizes="pageSizes"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
    <!-- 模态 - 查看 -->
    <el-dialog
      :close-on-click-modal="false"
      width="70%"
      title="查看"
      :visible.sync="viewVisible"
    >
      <el-form
        :model="babysitterForm"
        :rules="babysitterRules"
        ref="babysitterForm"
        size="medium"
        label-width="80px"
        disabled
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="轮播图" label-width="80px">
              <div class="flex flex-wrap">
                <div
                  v-for="(item, index) in coversArr"
                  :key="index"
                  class="job-img"
                >
                  <el-image
                    :src="item"
                    fit="cover"
                    :preview-src-list="coversArr"
                  />
                </div>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="工装照" label-width="80px">
              <div class="flex flex-wrap">
                <el-image
                  v-if="babysitterForm.avatar"
                  style="width: 100px; height: 100px; margin-right: 10px"
                  fit="cover"
                  :src="babysitterForm.avatar"
                  :preview-src-list="[babysitterForm.avatar]"
                />
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="姓名" label-width="80px" prop="name">
              <el-input size="small" v-model="babysitterForm.name" placeholder="输入姓名" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="年龄" label-width="80px">
              <el-input size="small" v-model="babysitterForm.age" placeholder="输入年龄" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="属相" label-width="80px">
              <el-select size="small" v-model="babysitterForm.chinese_zodiac" placeholder="选择属相">
                <el-option
                  v-for="(item, index) in chineseZodiacArr"
                  :key="index"
                  :label="item"
                  :value="item"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="星座" label-width="80px">
              <el-select size="small" v-model="babysitterForm.constellation" placeholder="选择星座">
                <el-option
                  v-for="(item, index) in constellationArr"
                  :key="index"
                  :label="item"
                  :value="item"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="身高" label-width="80px">
              <el-input size="small" v-model="babysitterForm.height" placeholder="输入身高" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="体重" label-width="80px">
              <el-input size="small" v-model="babysitterForm.weight" placeholder="输入体重" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="民族" label-width="80px">
              <el-input size="small" v-model="babysitterForm.nation" placeholder="输入民族" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="籍贯" label-width="80px">
              <el-input size="small" v-model="babysitterForm.census" placeholder="输入籍贯" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="文化程度" label-width="80px">
              <el-select size="small" v-model="babysitterForm.education" placeholder="文化程度">
                <el-option
                  v-for="(item, index) in educationArr"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="经验" label-width="80px">
              <el-input size="small" v-model="babysitterForm.experience" placeholder="输入经验" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="语言能力" label-width="80px">
              <el-input size="small" v-model="babysitterForm.ability_language" placeholder="输入语言能力" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="烹饪能力" label-width="80px">
              <el-input size="small" type="textarea" v-model="babysitterForm.ability_cook" placeholder="输入烹饪能力" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="家庭成员" label-width="80px">
              <el-input size="small" type="textarea" v-model="babysitterForm.family_members" placeholder="输入家庭成员" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="客户印象" label-width="80px">
              <el-input size="small" type="textarea" v-model="babysitterForm.impression" placeholder="输入客户印象" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item class="tag" label="服务标签" label-width="80px">
              <el-tag
                v-for="tag in tag.dynamicTags"
                :key="tag"
                :disable-transitions="false"
              >
                {{ tag }}
              </el-tag>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="考核评定" label-width="80px">
              <el-input
                :rows="4"
                size="small"
                type="textarea"
                v-model="babysitterForm.remark"
                placeholder="输入点评"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="服务价格" label-width="80px">
              <el-input size="small" v-model="babysitterForm.price" placeholder="如: ¥13888/30天" />
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 客户评价 -->
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="客户评价" label-width="80px">
              <div
                v-for="(item, index) in customerRemarkArr"
                :key="index"
                class="group"
              >
                <div class="content">
                  <template v-if="item.pictures">
                    <div
                      v-for="(picture, picIndex) in item.pictures"
                      :key="picIndex"
                      class="preview-img"
                    >
                      <el-image
                        class="picture"
                        :src="picture"
                        fit="cover"
                        :preview-src-list="item.pictures"
                      />
                    </div>
                  </template>
                </div>
                <el-input size="small" v-model="item.days" placeholder="时长" />
                <el-input
                  style="width: 50%; margin-right: 10px"
                  type="textarea"
                  size="small"
                  :autosize="{ minRows: 2, maxRows: 4}"
                  v-model="item.content"
                  placeholder="客户评价"
                />
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="客户肯定" label-width="80px">
              <div class="flex flex-wrap">
                <div
                  v-for="(item, index) in customerConfirmArr"
                  :key="index"
                  class="job-img"
                >
                  <el-image
                    fit="cover"
                    :src="item.picture"
                    :preview-src-list="[item.picture]"
                  />
                  <el-input v-model="item.title" size="mini" placeholder="描述" />
                </div>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <!-- 身份证 -->
          <el-col :span="12">
            <el-form-item label="身份证" label-width="80px">
              <div class="main">
                <div class="box">
                  <el-image
                    v-if="identity.up"
                    style="width: 100px; height: 63px; margin-bottom: 10px;"
                    fit="cover"
                    :src="identity.up"
                    :preview-src-list="[identity.up]"
                  />
                  <p>身份证正面</p>
                </div>
                <div class="box">
                  <el-image
                    v-if="identity.down"
                    style="width: 100px; height: 63px; margin-bottom: 10px;"
                    fit="cover"
                    :src="identity.down"
                    :preview-src-list="[identity.down]"
                  />
                  <p>身份证反面</p>
                </div>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="职业信息" label-width="80px">
              <div class="flex flex-wrap">
                <div
                  v-for="(item, index) in jobInfoArr"
                  :key="index"
                  class="job-img"
                >
                  <el-image
                    fit="cover"
                    :src="item.picture"
                    :preview-src-list="[item.picture]"
                  />
                  <el-input v-model="item.title" size="mini" placeholder="证书名称" />
                </div>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 背调 -->
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="体检报告" label-width="80px">
              <file-upload
                v-model="babysitterForm.medical_report"
                :limit="1"
                :file-type="['pdf']"
                :show-delete="false"
                @input="handleReport"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="背调信息" label-width="80px">
              <file-upload
                v-model="babysitterForm.reference_check"
                :limit="1"
                :file-type="['pdf']"
                :show-delete="false"
                @input="handleReferenceCheck"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="viewVisible = false">关 闭</el-button>
      </div>
    </el-dialog>
    <!-- 模态 - 新增与编辑 -->
    <el-dialog
      :close-on-click-modal="false"
      width="70%"
      :title="formTitle"
      :visible.sync="babysitterFormVisible"
    >
      <el-form
        :model="babysitterForm"
        :rules="babysitterRules"
        ref="babysitterForm"
        size="medium"
        label-width="80px"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="轮播图" label-width="80px">
              <div class="flex flex-wrap">
                <div
                  v-for="(item, index) in coversArr"
                  :key="index"
                  class="job-img"
                >
                  <el-image
                    :src="item"
                    fit="cover"
                    :preview-src-list="coversArr"
                  />
                  <div v-if="coversSortArr.length" class="bottom flex">
                    <el-button size="mini" icon="el-icon-delete" @click="deleteCover(index)" />
                    <el-input
                      v-model="coversSortArr[index].key"
                      size="mini"
                      style="margin-left: 5px;"
                    />
                  </div>
                </div>
                <image-upload
                  style="width: 100px; height: 100px"
                  dir="babysitter"
                  :play-back="false"
                  @input="handleCover"
                />
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="工装照" label-width="80px">
              <div class="flex flex-wrap">
                <el-image
                  v-if="babysitterForm.avatar"
                  style="width: 100px; height: 100px; margin-right: 10px"
                  fit="cover"
                  :src="babysitterForm.avatar"
                  :preview-src-list="[babysitterForm.avatar]"
                />
                <image-upload
                  style="width: 100px; height: 100px"
                  dir="babysitter"
                  :play-back="false"
                  @input="handleAvatar"
                />
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="姓名" label-width="80px" prop="name">
              <el-input size="small" v-model="babysitterForm.name" placeholder="输入姓名" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="年龄" label-width="80px">
              <el-input size="small" v-model="babysitterForm.age" placeholder="输入年龄" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="属相" label-width="80px">
              <el-select size="small" v-model="babysitterForm.chinese_zodiac" placeholder="选择属相">
                <el-option
                  v-for="(item, index) in chineseZodiacArr"
                  :key="index"
                  :label="item"
                  :value="item"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="星座" label-width="80px">
              <el-select size="small" v-model="babysitterForm.constellation" placeholder="选择星座">
                <el-option
                  v-for="(item, index) in constellationArr"
                  :key="index"
                  :label="item"
                  :value="item"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="身高" label-width="80px">
              <el-input size="small" v-model="babysitterForm.height" placeholder="输入身高" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="体重" label-width="80px">
              <el-input size="small" v-model="babysitterForm.weight" placeholder="输入体重" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="民族" label-width="80px">
              <el-input size="small" v-model="babysitterForm.nation" placeholder="输入民族" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="籍贯" label-width="80px">
              <el-input size="small" v-model="babysitterForm.census" placeholder="输入籍贯" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="文化程度" label-width="80px">
              <el-select size="small" v-model="babysitterForm.education" placeholder="文化程度">
                <el-option
                  v-for="(item, index) in educationArr"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="经验" label-width="80px">
              <el-input size="small" v-model="babysitterForm.experience" placeholder="输入经验" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="语言能力" label-width="80px">
              <el-input size="small" v-model="babysitterForm.ability_language" placeholder="输入语言能力" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="烹饪能力" label-width="80px">
              <el-input size="small" type="textarea" v-model="babysitterForm.ability_cook" placeholder="输入烹饪能力" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="家庭成员" label-width="80px">
              <el-input size="small" type="textarea" v-model="babysitterForm.family_members" placeholder="输入家庭成员" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="客户印象" label-width="80px">
              <el-input size="small" type="textarea" v-model="babysitterForm.impression" placeholder="输入客户印象" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item class="tag" label="服务标签" label-width="80px">
              <el-tag
                v-for="tag in tag.dynamicTags"
                :key="tag"
                closable
                :disable-transitions="false"
                @close="removeTag(tag)">
                {{ tag }}
              </el-tag>
              <el-input
                class="input-new-tag"
                v-if="tag.inputVisible"
                v-model="tag.inputValue"
                ref="saveTagInput"
                size="small"
                @keyup.enter.native="tagInputConfirm"
                @blur="tagInputConfirm"
              >
              </el-input>
              <el-button v-else class="button-new-tag" size="small" @click="addTagInput">添加标签</el-button>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="考核评定" label-width="80px">
              <el-input size="small" type="textarea" v-model="babysitterForm.remark" placeholder="输入点评" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="服务价格" label-width="80px">
              <el-input size="small" v-model="babysitterForm.price" placeholder="如: ¥13888/30天" />
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 客户评价 -->
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="客户评价" label-width="80px">
              <el-button size="mini" @click="addCustomerRemark">添加一行</el-button>
              <div
                v-for="(item, index) in customerRemarkArr"
                :key="index"
                class="group"
              >
                <div class="content">
                  <template v-if="item.pictures">
                    <div
                      v-for="(picture, picIndex) in item.pictures"
                      :key="picIndex"
                      class="preview-img"
                    >
                      <el-image
                        class="picture"
                        :src="picture"
                        fit="cover"
                        :preview-src-list="item.pictures"
                      />
                      <el-button size="mini" icon="el-icon-delete" @click="deletePicture(index, picIndex)" />
                    </div>
                  </template>
                  <image-upload
                    style="width: 50px; height: 50px; margin-right: 10px"
                    dir="product"
                    :play-back="false"
                    @input="handleCustomerPicture($event, index)"
                  />
                </div>
                <el-input size="small" v-model="item.days" placeholder="时长" />
                <el-input
                  style="width: 50%; margin-right: 10px"
                  type="textarea"
                  size="small"
                  :autosize="{ minRows: 2, maxRows: 4}"
                  v-model="item.content"
                  placeholder="客户评价"
                />
                <el-button v-if="customerRemarkArr.length" size="mini" @click="removeCustomerRemark(index)">删除</el-button>
                <el-button v-if="customerRemarkArr.length" size="mini" @click="upCustomerRemark(index)">置顶</el-button>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="客户肯定" label-width="80px">
              <div class="flex flex-wrap">
                <div
                  v-for="(item, index) in customerConfirmArr"
                  :key="index"
                  class="job-img"
                >
                  <el-image
                    fit="cover"
                    :src="item.picture"
                    :preview-src-list="[item.picture]"
                  />
                  <el-input v-model="item.title" size="mini" placeholder="描述" />
                  <el-button
                    style="margin-bottom: 10px"
                    size="mini"
                    icon="el-icon-delete"
                    @click="deleteCustomerConfirm(index)"
                  />
                </div>
                <image-upload
                  style="width: 100px; height: 100px"
                  dir="babysitter"
                  :play-back="false"
                  @input="handleCustomerConfirm"
                />
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <!-- 身份证 -->
          <el-col :span="12">
            <el-form-item label="身份证" label-width="80px">
              <div class="main">
                <div class="box">
                  <el-image
                    v-if="identity.up"
                    style="width: 100px; height: 63px; margin-bottom: 10px;"
                    fit="cover"
                    :src="identity.up"
                    :preview-src-list="[identity.up]"
                  />
                  <image-upload
                    style="width: 100px; height: 63px;"
                    dir="babysitter"
                    :play-back="false"
                    @input="handleIdentityUp"
                  />
                  <p>身份证正面</p>
                </div>
                <div class="box">
                  <el-image
                    v-if="identity.down"
                    style="width: 100px; height: 63px; margin-bottom: 10px;"
                    fit="cover"
                    :src="identity.down"
                    :preview-src-list="[identity.down]"
                  />
                  <image-upload
                    style="width: 100px; height: 63px"
                    dir="babysitter"
                    :play-back="false"
                    @input="handleIdentityDown"
                  />
                  <p>身份证反面</p>
                </div>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="职业信息" label-width="80px">
              <div class="flex flex-wrap">
                <div
                  v-for="(item, index) in jobInfoArr"
                  :key="index"
                  class="job-img"
                >
                  <el-image
                    fit="cover"
                    :src="item.picture"
                    :preview-src-list="[item.picture]"
                  />
                  <el-input v-model="item.title" size="mini" placeholder="证书名称" />
                  <el-button
                    style="margin-bottom: 10px"
                    size="mini"
                    icon="el-icon-delete"
                    @click="deleteJobInfo(index)"
                  />
                </div>
                <image-upload
                  style="width: 100px; height: 100px"
                  dir="babysitter"
                  :play-back="false"
                  @input="handleJobInfo"
                />
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 背调 -->
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="体检报告" label-width="80px">
              <file-upload
                v-model="babysitterForm.medical_report"
                :limit="1"
                :file-type="['pdf']"
                @input="handleReport"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="背调信息" label-width="80px">
              <file-upload
                v-model="babysitterForm.reference_check"
                :limit="1"
                :file-type="['pdf']"
                @input="handleReferenceCheck"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="cancel">取 消</el-button>
        <el-button size="small" type="primary" @click="save">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 模态 - 上传视频 -->
    <el-dialog
      :close-on-click-modal="false"
      title="上传视频"
      :visible.sync="videoFormVisible"
    >
      <el-form :model="videoForm">
        <el-form-item>
          <video-upload
            :id="currentBabysitterId"
            :file="videoFile"
            @finish="onVideoFinish"
          />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-popover
          placement="top"
          width="160"
          v-model="videoVisible">
          <p>确定要清空已上传的月嫂视频吗?</p>
          <div style="text-align: right; margin: 0">
            <el-button size="mini" type="text" @click="videoVisible = false">取消</el-button>
            <el-button type="primary" size="mini" @click="handleEmptyVideo">确定</el-button>
          </div>
          <el-button size="small" type="danger" slot="reference">清 空</el-button>
        </el-popover>
        <el-button
          size="small"
          type="primary"
          style="margin-left: 10px"
          @click="videoCancel()"
        >确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import VideoUpload from '@/components/VideoUpload'
import {
  getBabysitterList,
  deleteBabysitter,
  addBabysitter,
  updateBabysitter,
  emptyVideo,
  recommend,
  exportAll
} from '@/services/babysitter'

export default {
  name: 'Babysitter',
  components: {
    VideoUpload
  },
  data () {
    return {
      viewVisible: false,
      videoVisible: false,
      avatarKey: '',
      tableData: [],
      total: 0,
      pageSizes: [10, 20, 30, 40, 50, 100],
      queryInfo: {
        pageNum: 1,
        pageSize: 10
      },
      babysitterFormVisible: false,
      formTitle: '新增',
      formType: 'add',
      babysitterForm: {},
      babysitterRules: {
        name: [
          { required: true, message: '姓名不能为空', trigger: 'blur' }
        ]
      },
      chineseZodiacArr: {
        鼠: '鼠',
        牛: '牛',
        虎: '虎',
        兔: '兔',
        龙: '龙',
        蛇: '蛇',
        马: '马',
        羊: '羊',
        猴: '猴',
        鸡: '鸡',
        狗: '狗',
        猪: '猪'
      },
      constellationArr: {
        白羊座: '白羊座',
        金牛座: '金牛座',
        双子座: '双子座',
        巨蟹座: '巨蟹座',
        狮子座: '狮子座',
        处女座: '处女座',
        天秤座: '天秤座',
        天蝎座: '天蝎座',
        射手座: '射手座',
        摩羯座: '摩羯座',
        水瓶座: '水瓶座',
        双鱼座: '双鱼座'
      },
      educationArr: [
        { label: '小学', value: 1 },
        { label: '初中', value: 2 },
        { label: '中专', value: 3 },
        { label: '高中', value: 4 },
        { label: '大专', value: 5 },
        { label: '本科', value: 6 },
        { label: '研究生及以上', value: 7 }
      ],
      educationMap: {
        1: '小学',
        2: '初中',
        3: '中专',
        4: '高中',
        5: '大专',
        6: '本科',
        7: '研究生及以上'
      },
      coversArr: [],
      coversSortArr: [],
      tag: {
        dynamicTags: [],
        inputVisible: false,
        inputValue: ''
      },
      customerRemarkArr: [],
      identity: {},
      jobInfoArr: [{ picture: '', title: '' }],
      customerConfirmArr: [{ picture: '', title: '' }],
      currentBabysitterId: 0,
      videoFile: {},
      videoFormVisible: false,
      videoForm: {}
    }
  },
  created () {
    this.getBabysitterList()
  },
  methods: {
    handleAvatar (val) {
      this.$set(this.babysitterForm, 'avatar', val)
    },
    handleIdentityUp (val) {
      this.$set(this.identity, 'up', val)
    },
    handleIdentityDown (val) {
      this.$set(this.identity, 'down', val)
    },
    async getBabysitterList () {
      const { data } = await getBabysitterList(this.queryInfo)
      this.tableData = data.data
      this.total = data.meta.total
    },
    add () {
      this.babysitterFormVisible = true
      this.initData({})
    },
    reset () {
      delete this.queryInfo.name
      delete this.queryInfo.number
      this.getBabysitterList()
    },
    handleView (index, row) {
      this.viewVisible = true
      for (const key in this.babysitterForm) {
        delete this.babysitterForm[key]
      }
      this.babysitterForm = { ...row }
      this.coversArr = row.covers || []
      this.tag.dynamicTags = row.tags
      this.customerRemarkArr = row.customer_remark || []
      // 判断身份证信息
      this.identity = row.identity || {}
      this.jobInfoArr = row.job_info || []
      this.customerConfirmArr = row.customer_confirm || []
    },
    handleEdit (index, row) {
      this.babysitterFormVisible = true
      if (this.$refs.babysitterForm) {
        this.$refs.babysitterForm.resetFields()
      }
      this.formTitle = '编辑'
      this.formType = 'edit'
      this.initData(row)
    },
    handleDelete (index, row) {
      this.$confirm('请确认操作', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await deleteBabysitter(row.id)
        this.getBabysitterList()
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 标签
    addTagInput () {
      this.tag.inputVisible = true
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus()
      })
    },
    tagInputConfirm () {
      const inputValue = this.tag.inputValue
      if (inputValue) {
        if (this.tag.dynamicTags && this.tag.dynamicTags.length) {
          this.tag.dynamicTags.push(inputValue)
        } else {
          this.tag.dynamicTags = [inputValue]
        }
      }
      this.tag.inputVisible = false
      this.tag.inputValue = ''
    },
    removeTag (tag) {
      this.tag.dynamicTags.splice(this.tag.dynamicTags.indexOf(tag), 1)
    },
    handleSizeChange (newSize) {
      this.queryInfo.pageSize = newSize
      this.getBabysitterList()
    },
    handleCurrentChange (newPage) {
      this.queryInfo.pageNum = newPage
      this.getBabysitterList()
    },
    addCustomerRemark () {
      this.customerRemarkArr.unshift({ days: '', content: '', pictures: [] })
    },
    removeCustomerRemark (index) {
      this.customerRemarkArr.splice(index, 1)
    },
    // 指定评论
    upCustomerRemark (index) {
      const result = this.customerRemarkArr.splice(index, 1)[0]
      this.customerRemarkArr.unshift(result)
    },
    handleCustomerPicture (val, index) {
      const pictures = this.customerRemarkArr[index].pictures
      if (!pictures || pictures.length === 0) {
        this.$set(this.customerRemarkArr[index], 'pictures', [])
      }
      this.customerRemarkArr[index].pictures.push(val)
    },
    deletePicture (index, picIndex) {
      this.customerRemarkArr[index].pictures.splice(picIndex, 1)
    },
    deleteJobInfo (index) {
      this.jobInfoArr.splice(index, 1)
    },
    handleJobInfo (val) {
      this.jobInfoArr.push({ picture: val, title: '' })
    },
    deleteCustomerConfirm (index) {
      this.customerConfirmArr.splice(index, 1)
    },
    handleCustomerConfirm (val) {
      this.customerConfirmArr.push({ picture: val, title: '' })
    },
    deleteCover (index) {
      this.coversArr.splice(index, 1)
      // 处理 banner 顺序
      this.handleCoversSortArr()
    },
    handleCover (val) {
      this.coversArr.push(val)
      // 处理 banner 顺序
      this.handleCoversSortArr()
    },
    handleReport (val) {
      this.babysitterForm.medical_report = val
    },
    handleReferenceCheck (val) {
      this.babysitterForm.reference_check = val
    },
    handleCoversSortArr () {
      this.coversSortArr = []
      this.coversArr.forEach((item, index) => {
        const obj = {
          key: index,
          value: item
        }
        this.coversSortArr.push(obj)
      })
    },
    initData (row) {
      // 清空对象属性
      for (const key in this.babysitterForm) {
        delete this.babysitterForm[key]
      }
      if (row.id) {
        this.formTitle = '编辑'
        this.formType = 'edit'
        this.babysitterForm = { ...row }
        this.coversArr = row.covers || []
        // 处理 banner 顺序
        this.handleCoversSortArr()
        this.tag.dynamicTags = row.tags
        this.customerRemarkArr = row.customer_remark || []
        // 判断身份证信息
        this.identity = row.identity || {}
        this.jobInfoArr = row.job_info || []
        this.customerConfirmArr = row.customer_confirm || []
      } else {
        this.formTitle = '新增'
        this.formType = 'add'
        this.coversArr = []
        this.coversSortArr = []
        this.identity = {}
        this.jobInfoArr = []
        this.customerConfirmArr = []
        this.customerRemarkArr = []
        this.$set(this.tag, 'dynamicTags', [])
      }
    },
    cancel () {
      this.babysitterFormVisible = false
      this.initData({})
    },
    save () {
      this.$refs.babysitterForm.validate(async valid => {
        if (valid) {
          const formData = { ...this.babysitterForm }
          // 处理 is_recommend 类型
          if (formData.is_recommend) {
            formData.is_recommend = 1
          } else {
            formData.is_recommend = 0
          }
          // 处理 covers 顺序
          const arr = this.coversSortArr
          arr.sort((a, b) => Number(a.key) - Number(b.key))
          const finalCoversArr = []
          arr.forEach(item => {
            finalCoversArr.push(item.value)
          })
          formData.covers = finalCoversArr
          formData.customer_remark = this.customerRemarkArr
          formData.tags = this.tag.dynamicTags
          formData.identity = this.identity
          formData.job_info = this.jobInfoArr
          formData.customer_confirm = this.customerConfirmArr
          this.formType === 'add' ? await addBabysitter(formData) : await updateBabysitter(formData.id, formData)
          this.babysitterFormVisible = false
          this.getBabysitterList()
          this.$message({
            type: 'success',
            message: `${this.formTitle}月嫂成功`
          })
          // 清空数据
          this.initData({})
        } else {
          this.$message.error('必填项不能为空')
        }
      })
    },
    videoCancel () {
      this.currentBabysitterId = 0
      this.videoFile = {}
      this.videoFormVisible = false
    },
    handleVideo (index, row) {
      this.currentBabysitterId = row.id
      this.videoFile = row.video
      this.videoFormVisible = true
    },
    onVideoFinish () {
      this.currentBabysitterId = 0
      this.videoFile = {}
      this.videoFormVisible = false
      this.getBabysitterList()
    },
    async handleEmptyVideo () {
      await emptyVideo(this.currentBabysitterId)
      this.videoVisible = false
      this.videoFormVisible = false
      this.getBabysitterList()
      this.$message({
        type: 'success',
        message: '清除成功'
      })
    },
    async onRecommendChange (value, id) {
      try {
        const params = {
          babysitter_id: id,
          is_recommend: value ? 1 : 0
        }
        await recommend(params)
        this.$message({
          type: 'success',
          message: '编辑成功'
        })
      } catch (e) {
        console.log(e)
      }
    },
    async recommendSortSave (row) {
      try {
        const params = {
          babysitter_id: row.id,
          recommend_sort: row.recommend_sort
        }
        await recommend(params)
        this.$message({
          type: 'success',
          message: '编辑成功'
        })
      } catch (e) {
        console.log(e)
      }
    },
    async exportAll () {
      const { data } = await exportAll()
      const blob = new Blob([data], { type: 'application/octet-stream,charset=UTF-8' })
      const downloadElement = document.createElement('a')
      const href = window.URL.createObjectURL(blob)
      downloadElement.href = href
      downloadElement.download = `月嫂-${Date.now()}.xlsx`
      document.body.appendChild(downloadElement)
      downloadElement.click()
      document.body.removeChild(downloadElement)
      window.URL.revokeObjectURL(href)
    }
  }
}
</script>

<style lang="scss">
.babysitter {
  .flex {
    display: flex;
  }
  .flex-wrap {
    flex-wrap: wrap;
  }
  .card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .form {
      display: flex;
      align-items: center;
      .search-item {
        margin-right: 5px;
      }
    }
  }
  box-sizing: border-box;
  padding: 15px;
  .el-table {
    .avatar {
      width: 50px;
      height: 50px;
    }
  }
  .tag {
    .el-tag + .el-tag {
      margin-left: 10px;
    }
    .button-new-tag {
      height: 32px;
      line-height: 30px;
      padding-top: 0;
      padding-bottom: 0;
    }
    .input-new-tag {
      width: 90px;
      margin-left: 10px;
      vertical-align: bottom;
    }
  }
  .group {
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;
    .content {
      width: 20%;
    }
    .el-image {
      height: 50px;
      margin-bottom: 5px;
    }
    .el-input {
      width: 80px;
      margin-right: 10px;
    }
    .avatar-uploader {
      margin-right: 10px;
      .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
      }
      .el-upload:hover {
        border-color: #409EFF;
      }
      .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 50px;
        height: 50px;
        line-height: 50px;
        text-align: center;
      }
      .avatar {
        width: 50px;
        height: 50px;
        display: block;
      }
    }
  }
  .pagination {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 20px;
  }
  .el-dialog {
    .content {
      display: flex;
      flex-wrap: wrap;
      .preview-img {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 5px;
        margin-bottom: 5px;
        width: 50px;
      }
    }
    .main {
      display: flex;
      .box {
        display: flex;
        flex-direction: column;
        align-items: center;
        &:first-child {
          margin-right: 15px;
        }
      }
    }
    .job-img {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 10px;
      width: 100px;
      height: 140px;
      .el-image {
        margin-bottom: 5px;
        width: 100px;
        height: 100px;
      }
    }
  }
}
</style>
