import request from '@/utils/request'
import qs from 'qs'

// 列表
export const getBabysitterList = params => {
  return request({
    method: 'GET',
    url: '/babysitters',
    params
  })
}

// 删除
export const deleteBabysitter = id => {
  return request({
    method: 'DELETE',
    url: `/babysitters/${id}`
  })
}

// 新增
export const addBabysitter = data => {
  return request({
    method: 'POST',
    url: '/babysitters',
    data
  })
}

// 编辑
export const updateBabysitter = (id, data) => {
  return request({
    method: 'PATCH',
    url: `/babysitters/${id}`,
    data,
    transformRequest: [
      function (data) {
        // 处理 []
        for (const i in data) {
          if (Array.isArray(data[i])) {
            if (data[i].length === 0) {
              data[i] = null
            }
          }
        }
        return qs.stringify(data)
      }
    ]
  })
}

// 上传视频
export const uploadVideo = (id, data) => {
  return request({
    method: 'PATCH',
    url: `/babysitters/${id}/upload-video`,
    data,
    transformRequest: [
      function (data) {
        return qs.stringify(data)
      }
    ]
  })
}

// 清空视频
export const emptyVideo = id => {
  return request({
    method: 'PATCH',
    url: `/babysitters/${id}/empty-video`
  })
}

// 推荐
export const recommend = params => {
  return request({
    method: 'PATCH',
    url: '/babysitters/recommend',
    params
  })
}

// 导出
export const exportAll = () => {
  return request({
    method: 'POST',
    url: '/babysitters/export',
    responseType: 'blob'
  })
}
